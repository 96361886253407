import Dashboard from 'assets/images/dashboard.png';
// import KPI from 'assets/images/KPI.png';


export const ProblemSection = () => {
  return (
    <section id="problem" className="mt-[48px]">
      <div className="container-custom">
        <div className="flex items-center justify-between gap-[32px] xl:flex-row flex-col-reverse xl:gap-[40px] mb-[48px]">
          <img className="w-[100%] max-w-[715px] hidden md:block" src={Dashboard} alt="Dashboard" />
          <div>
            <div className="font-semibold leading-[120%] text-[#1D2E54] text-[24px] mb-3 xl:mb-4 xl:text-[26px]">
              Dashboards lack external context</div>
            <div className="mb-[20px] leading-150% text-[#45484C] text-[16px] xl:mb-[32px] xl:text-[18px]">
              ... and they raise more questions than they answer
            </div>
            <ul className="flex gap-[16px] flex-col xl:gap-[20px] max-w-[544px]">
              <li className="flex gap-3 items-center leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                <svg className="h-[36px] min-w-[36px]" width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.5" width="36" height="36" rx="18" fill="#F3F2FF" />
                  <path d="M18.0003 11H21.5003C22.9005 11 23.6005 11 24.1353 11.2725C24.6057 11.5122 24.9882 11.8946 25.2278 12.365C25.5003 12.8998 25.5003 13.5999 25.5003 15V22C25.5003 23.4001 25.5003 24.1002 25.2278 24.635C24.9882 25.1054 24.6057 25.4878 24.1353 25.7275C23.6005 26 22.9005 26 21.5003 26H14.5003C13.1002 26 12.4001 26 11.8653 25.7275C11.3949 25.4878 11.0125 25.1054 10.7728 24.635C10.5003 24.1002 10.5003 23.4001 10.5003 22V18.5M14.667 19.3333V22.6667M21.3337 17.6667V22.6667M18.0003 14.3333V22.6667M9.66699 12.6667H14.667" stroke="#445082" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div className="">
                  Are elections impacting my metrics?
                </div>
              </li>
              <li className="flex gap-3 items-center leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                <svg className="h-[36px] min-w-[36px]" width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.5" width="36" height="36" rx="18" fill="#F3F2FF" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3333 10.1665C11.7936 10.1665 12.1667 10.5396 12.1667 10.9998V16.8332C12.1667 17.2934 11.7936 17.6665 11.3333 17.6665C10.8731 17.6665 10.5 17.2934 10.5 16.8332V10.9998C10.5 10.5396 10.8731 10.1665 11.3333 10.1665ZM18 10.1665C18.4602 10.1665 18.8333 10.5396 18.8333 10.9998V14.3332H20.5C20.9602 14.3332 21.3333 14.7063 21.3333 15.1665C21.3333 15.6267 20.9602 15.9998 20.5 15.9998H15.5C15.0398 15.9998 14.6667 15.6267 14.6667 15.1665C14.6667 14.7063 15.0398 14.3332 15.5 14.3332H17.1667V10.9998C17.1667 10.5396 17.5398 10.1665 18 10.1665ZM24.6667 10.1665C25.1269 10.1665 25.5 10.5396 25.5 10.9998V18.4998C25.5 18.9601 25.1269 19.3332 24.6667 19.3332C24.2064 19.3332 23.8333 18.9601 23.8333 18.4998V10.9998C23.8333 10.5396 24.2064 10.1665 24.6667 10.1665ZM18 17.6665C18.4602 17.6665 18.8333 18.0396 18.8333 18.4998V25.9998C18.8333 26.4601 18.4602 26.8332 18 26.8332C17.5398 26.8332 17.1667 26.4601 17.1667 25.9998V18.4998C17.1667 18.0396 17.5398 17.6665 18 17.6665ZM8 20.1665C8 19.7063 8.3731 19.3332 8.83333 19.3332H13.8333C14.2936 19.3332 14.6667 19.7063 14.6667 20.1665C14.6667 20.6267 14.2936 20.9998 13.8333 20.9998H12.1667V25.9998C12.1667 26.4601 11.7936 26.8332 11.3333 26.8332C10.8731 26.8332 10.5 26.4601 10.5 25.9998V20.9998H8.83333C8.3731 20.9998 8 20.6267 8 20.1665ZM21.3333 21.8332C21.3333 21.3729 21.7064 20.9998 22.1667 20.9998H27.1667C27.6269 20.9998 28 21.3729 28 21.8332C28 22.2934 27.6269 22.6665 27.1667 22.6665H25.5V25.9998C25.5 26.4601 25.1269 26.8332 24.6667 26.8332C24.2064 26.8332 23.8333 26.4601 23.8333 25.9998V22.6665H22.1667C21.7064 22.6665 21.3333 22.2934 21.3333 21.8332Z" fill="#445082" />
                </svg>
                <div className="">
                  Did school holidays shift this year?
                </div>
              </li>
              <li className="flex gap-3 items-center leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                <svg className="h-[36px] min-w-[36px]" width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.5" width="36" height="36" rx="18" fill="#F3F2FF" />
                  <path d="M25.5 26H11.8333C11.3666 26 11.1333 26 10.955 25.9092C10.7982 25.8293 10.6707 25.7018 10.5908 25.545C10.5 25.3667 10.5 25.1334 10.5 24.6667V11M25.5 21L20.9714 16.4714C20.8064 16.3064 20.7239 16.2239 20.6288 16.193C20.5451 16.1658 20.4549 16.1658 20.3712 16.193C20.2761 16.2239 20.1936 16.3064 20.0286 16.4714L18.4714 18.0286C18.3064 18.1936 18.2239 18.2761 18.1288 18.307C18.0451 18.3342 17.9549 18.3342 17.8712 18.307C17.7761 18.2761 17.6936 18.1936 17.5286 18.0286L13.8333 14.3333M25.5 21H22.1667M25.5 21V17.6667" stroke="#445082" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div className="">
                  Will Olympics create tough m/m comparison?
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* <div className="flex items-center justify-between gap-[32px] flex-col xl:flex-row xl:gap-[60px] mb-[80px]">
          <div>
            <div className="font-semibold leading-[120%] text-[#1D2E54] text-[24px] mb-3 xl:mb-4 xl:text-[26px]">
              What, Why, and How
              behind Business KPIs</div>
            <div className="mb-[20px] leading-150% text-[#45484C] text-[16px] xl:mb-[32px] xl:text-[18px]">
              Changes in KPIs often result from hidden factors, such as:
            </div>
            <ul className="flex gap-[16px] flex-col xl:gap-[20px] max-w-[544px]">
              <li className="flex gap-3 items-center leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                <svg className="h-[36px] min-w-[36px]" width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.5" width="36" height="36" rx="18" fill="#FBF2FF" />
                  <path d="M21.3337 21.8332C21.3337 21.8332 20.0837 20.1665 18.0003 20.1665C15.917 20.1665 14.667 21.8332 14.667 21.8332M20.5003 15.9998H20.5087M15.5003 15.9998H15.5087M26.3337 18.4998C26.3337 23.1022 22.6027 26.8332 18.0003 26.8332C13.398 26.8332 9.66699 23.1022 9.66699 18.4998C9.66699 13.8975 13.398 10.1665 18.0003 10.1665C22.6027 10.1665 26.3337 13.8975 26.3337 18.4998ZM20.917 15.9998C20.917 16.23 20.7304 16.4165 20.5003 16.4165C20.2702 16.4165 20.0837 16.23 20.0837 15.9998C20.0837 15.7697 20.2702 15.5832 20.5003 15.5832C20.7304 15.5832 20.917 15.7697 20.917 15.9998ZM15.917 15.9998C15.917 16.23 15.7304 16.4165 15.5003 16.4165C15.2702 16.4165 15.0837 16.23 15.0837 15.9998C15.0837 15.7697 15.2702 15.5832 15.5003 15.5832C15.7304 15.5832 15.917 15.7697 15.917 15.9998Z" stroke="#AD81D2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div className="">
                  shifts in specific user groups
                </div>
              </li>
              <li className="flex gap-3 items-center leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                <svg className="h-[36px] min-w-[36px]" width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.5" width="36" height="36" rx="18" fill="#FBF2FF" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3333 10.1665C11.7936 10.1665 12.1667 10.5396 12.1667 10.9998V16.8332C12.1667 17.2934 11.7936 17.6665 11.3333 17.6665C10.8731 17.6665 10.5 17.2934 10.5 16.8332V10.9998C10.5 10.5396 10.8731 10.1665 11.3333 10.1665ZM18 10.1665C18.4602 10.1665 18.8333 10.5396 18.8333 10.9998V14.3332H20.5C20.9602 14.3332 21.3333 14.7063 21.3333 15.1665C21.3333 15.6267 20.9602 15.9998 20.5 15.9998H15.5C15.0398 15.9998 14.6667 15.6267 14.6667 15.1665C14.6667 14.7063 15.0398 14.3332 15.5 14.3332H17.1667V10.9998C17.1667 10.5396 17.5398 10.1665 18 10.1665ZM24.6667 10.1665C25.1269 10.1665 25.5 10.5396 25.5 10.9998V18.4998C25.5 18.9601 25.1269 19.3332 24.6667 19.3332C24.2064 19.3332 23.8333 18.9601 23.8333 18.4998V10.9998C23.8333 10.5396 24.2064 10.1665 24.6667 10.1665ZM18 17.6665C18.4602 17.6665 18.8333 18.0396 18.8333 18.4998V25.9998C18.8333 26.4601 18.4602 26.8332 18 26.8332C17.5398 26.8332 17.1667 26.4601 17.1667 25.9998V18.4998C17.1667 18.0396 17.5398 17.6665 18 17.6665ZM8 20.1665C8 19.7063 8.3731 19.3332 8.83333 19.3332H13.8333C14.2936 19.3332 14.6667 19.7063 14.6667 20.1665C14.6667 20.6267 14.2936 20.9998 13.8333 20.9998H12.1667V25.9998C12.1667 26.4601 11.7936 26.8332 11.3333 26.8332C10.8731 26.8332 10.5 26.4601 10.5 25.9998V20.9998H8.83333C8.3731 20.9998 8 20.6267 8 20.1665ZM21.3333 21.8332C21.3333 21.3729 21.7064 20.9998 22.1667 20.9998H27.1667C27.6269 20.9998 28 21.3729 28 21.8332C28 22.2934 27.6269 22.6665 27.1667 22.6665H25.5V25.9998C25.5 26.4601 25.1269 26.8332 24.6667 26.8332C24.2064 26.8332 23.8333 26.4601 23.8333 25.9998V22.6665H22.1667C21.7064 22.6665 21.3333 22.2934 21.3333 21.8332Z" fill="#AD81D2" />
                </svg>
                <div className="">
                  external market events
                </div>
              </li>
            </ul>
          </div>
          <img className="w-[100%] max-w-[715px]" src={KPI} alt="Mockup." />
        </div> */}

      </div>
    </section>
  )
}
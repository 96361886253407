import { CheckBorderIcon } from "../../components/UI/svgIcons"

export const PricingSection = () => {
  return (
    <section id="pricing" className="pb-[48px]">
      <div className="container-custom">
        <h2 className="font-semibold text-center leading-[120%] text-[#1D2E54] text-[24px] lg:text-[32px] lg:mb-4 mb-3">
          Pricing Plans
        </h2>
        {/* <!-- Cards for desktop --> */}
        <div className="flex items-center flex-col lg:flex-row justify-between gap-[40px]">
          <div className="relative border border-[#E6E6E7] max-w-[350px] w-[100%] rounded-[16px] shadow-lg px-[32px] py-10">
            <div className="font-semibold leading-[140%] absolute bg-[#F3F2FF] rounded-[50px] shadow text-[#1D2E54] text-[14px] top-2 -right-6 px-[29px] py-[6px] hidden">
              Most popular!
            </div>
            <div className="text-center mb-[32px]">
              <div className="font-semibold leading-[120%] mb-4 text-[#445082] lg:text-[32px] text-[24px]">$500/month</div>
              <div className="font-semibold text-[#445082] leading-[130%] mb-1 text-[20px]">Basic</div>
              <div className="leading-[150%] text-[16px] text-[#45484C]">Billed annually.</div>
            </div>
            <ul className="flex gap-4 flex-col mb-10">
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">Free Trial</div>
              </li>
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">Unlimited CSV upload</div>
              </li>
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">5M rows and 1GB file size</div>
              </li>
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">5 user seats</div>
              </li>
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">Advanced insights</div>
              </li>
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">Slack and email support</div>
              </li>
            </ul>
          </div>
          <div className="relative border border-[#E6E6E7] max-w-[350px] w-[100%] rounded-[16px] shadow-lg px-[32px] py-10">
            <div className="font-semibold leading-[140%] absolute bg-[#F3F2FF] rounded-[50px] shadow text-[#1D2E54] text-[14px] top-2 -right-6 px-[29px] py-[6px] hidden">
              Most popular!
            </div>
            <div className="text-center mb-[32px]">
              <div className="font-semibold leading-[120%] mb-4 text-[#445082] lg:text-[32px] text-[24px]">$2,000/month</div>
              <div className="font-semibold text-[#445082] leading-[130%] mb-1 text-[20px]">Business</div>
              <div className="leading-[150%] text-[16px] text-[#45484C]">Billed annually.</div>
            </div>
            <ul className="flex gap-4 flex-col mb-10">
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">Everything in Basic, plus</div>
              </li>
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">5 data connections</div>
              </li>
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">Executive summary and alerts</div>
              </li>
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">20 user seats</div>
              </li>
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">Engineering Support for data prep</div>
              </li>
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">Phone support</div>
              </li>
            </ul>
          </div>
          <div className="relative border border-[#E6E6E7] max-w-[350px] w-[100%] rounded-[16px] shadow-lg px-[32px] py-10">
            <div className="font-semibold leading-[140%] absolute bg-[#F3F2FF] rounded-[50px] shadow text-[#1D2E54] text-[14px] top-2 -right-6 px-[29px] py-[6px] hidden">
              Most popular!
            </div>
            <div className="text-center mb-[32px]">
              <div className="font-semibold leading-[120%] mb-4 text-[#445082] lg:text-[32px] text-[24px]">$5,000/month</div>
              <div className="font-semibold text-[#445082] leading-[130%] mb-1 text-[20px]">Enterprise</div>
              <div className="leading-[150%] text-[16px] text-[#45484C]">Billed annually.</div>
            </div>
            <ul className="flex gap-4 flex-col mb-10">
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">Everything in Business, plus</div>
              </li>
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">Deploy on-prem option</div>
              </li>
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">Chat with data in slack</div>
              </li>
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">Unlimited user seats</div>
              </li>
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">SLA commitment</div>
              </li>
              <li className="flex items-center gap-3">
                <CheckBorderIcon />
                <div className="leading-[150%] text-[#2B3C6B] text-[16px]">Personalized + Priority service</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
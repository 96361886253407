import PosterImg from 'assets/images/poster-img.png';
import { Link } from 'react-router-dom';

export const IntroSection = () => {
    return (
        <section id="home" className="bg-[#f7f8fa] lg:pt-[140px] pt-[60px] ">
          <div className="flex items-center flex-col justify-between gap-[32px] xl:flex-row xl:gap-[20px] container-custom">
            <div className="lg:pl-10 max-w-[712px]">
              <h1 className="font-semibold leading-[120%] mb-3 lg:mb-4 lg:text-[42px] text-[#151618] text-[32px]">
              Discover <span className="text-[#445082]">Why</span> KPIs are changing
              </h1>
              <div className="leading-[130%] text-[16px] lg:text-[20px] text-[#45484C] lg:mb-[48px] mb-10">
                Combine external signals with data to explain KPI fluctuations
              </div>
              <div className="flex items-center gap-3 flex-col lg:flex-row lg:max-w-[unset] lg:mx-[unset] max-w-[344px] mb-4 mx-auto">
                {/* <Link to="/register" className="flex items-center justify-center font-semibold border border-[#CACBCB] duration-300 ease-in-out hover:bg-[#F3F2FF] hover:border-[#AAABAC] leading-[120%] lg:h-[50px] lg:min-w-[180px] min-w-full p-3 rounded-[10px] text-[#2C2E30] text-[17px]">
                  Get Started
                </Link> */}
                <Link to="/book" className="flex items-center justify-center font-semibold leading-[140%] duration-300 ease-in-out rounded-xl text-center bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff] lg:h-[50px] lg:min-w-[180px] min-w-full p-3 text-[17px]">
                  Demo with Founder
                </Link>
              </div>
              {/* <div className="font-medium leading-[140%] lg:text-[14px] text-[#35383B] text-[12px]">No Credit Card Required
              </div> */}
            </div>
            <img className="lg:block max-w-[477px] w-[100%]   pr-10" src={PosterImg} alt="Poster." />
          </div>
        </section>
    )
}
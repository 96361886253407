export const BenefitSection = () => {
    return (
      <section id="benefits" className="mt-[48px]">
        <div className="container-custom">
          <div className="text-center mb-[32px] lg:mb-10">
            <h2 className="font-semibold leading-[120%] text-[#1D2E54] lg:text-[32px] lg:mb-3 mb-2 text-[24px]">
              DataScoop superpowers analysts
            </h2>
            <div className="leading-[150%] text-[16px] text-[#45484C] lg:text-[20px]">
              to comprehensively answer complex business questions
            </div>
          </div>
  
          <div className="flex items-center flex-col lg:flex-row justify-between gap-[40px] mb-[48px]">
            <div className="relative border border-[#E6E6E7] max-w-[350px] min-w-[280px] rounded-[16px] shadow-lg px-[32px] py-10">
              <div className="text-center">
                <div className="font-semibold leading-[120%] mb-4 text-[#445082] text-[40px] p-[25px] bg-[#f7f8fa] border-[#b0b5ef] border-[2px] rounded-[16px]">5X</div>
                <div className="font-semibold text-[#445082] leading-[130%] mb-2 text-[22px]">Faster</div>
                <div className="leading-[150%] text-[16px] text-[#45484C]">Response to Trends</div>
              </div>
              
            </div>
            <div className="relative border border-[#E6E6E7] max-w-[350px] min-w-[280px] rounded-[16px] shadow-lg px-[32px] py-10">
              <div className="text-center">
                <div className="font-semibold leading-[120%] mb-4 text-[#445082] text-[40px] p-[25px] bg-[#f7f8fa] border-[#b0b5ef] border-[2px] rounded-[16px]">10X</div>
                <div className="font-semibold text-[#445082] leading-[130%] mb-2 text-[22px]">More</div>
                <div className="leading-[150%] text-[16px] text-[#45484C]">Questions Answered</div>
              </div>
              
            </div>
            <div className="relative border border-[#E6E6E7] max-w-[350px] min-w-[280px] rounded-[16px] shadow-lg px-[32px] py-10">
              <div className="text-center">
                <div className="font-semibold leading-[120%] mb-4 text-[#445082] text-[40px] p-[25px] bg-[#f7f8fa] border-[#b0b5ef] border-[2px] rounded-[16px]">500</div>
                <div className="font-semibold text-[#445082] leading-[130%] mb-2 text-[22px]">Saved</div>
                <div className="leading-[150%] text-[16px] text-[#45484C]">Hours per Quarter</div>
              </div>
            </div>
          </div>
  
          {/* <div className="flex items-center justify-between gap-[32px] flex-col xl:flex-row xl:gap-[60px] mb-[80px]">
            <div>
              <div className="font-semibold leading-[120%] text-[#1D2E54] text-[24px] mb-3 xl:mb-4 xl:text-[26px]">
                What, Why, and How
                behind Business KPIs</div>
              <div className="mb-[20px] leading-150% text-[#45484C] text-[16px] xl:mb-[32px] xl:text-[18px]">
                Changes in KPIs often result from hidden factors, such as:
              </div>
              <ul className="flex gap-[16px] flex-col xl:gap-[20px] max-w-[544px]">
                <li className="flex gap-3 items-center leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                  <svg className="h-[36px] min-w-[36px]" width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="0.5" width="36" height="36" rx="18" fill="#FBF2FF" />
                    <path d="M21.3337 21.8332C21.3337 21.8332 20.0837 20.1665 18.0003 20.1665C15.917 20.1665 14.667 21.8332 14.667 21.8332M20.5003 15.9998H20.5087M15.5003 15.9998H15.5087M26.3337 18.4998C26.3337 23.1022 22.6027 26.8332 18.0003 26.8332C13.398 26.8332 9.66699 23.1022 9.66699 18.4998C9.66699 13.8975 13.398 10.1665 18.0003 10.1665C22.6027 10.1665 26.3337 13.8975 26.3337 18.4998ZM20.917 15.9998C20.917 16.23 20.7304 16.4165 20.5003 16.4165C20.2702 16.4165 20.0837 16.23 20.0837 15.9998C20.0837 15.7697 20.2702 15.5832 20.5003 15.5832C20.7304 15.5832 20.917 15.7697 20.917 15.9998ZM15.917 15.9998C15.917 16.23 15.7304 16.4165 15.5003 16.4165C15.2702 16.4165 15.0837 16.23 15.0837 15.9998C15.0837 15.7697 15.2702 15.5832 15.5003 15.5832C15.7304 15.5832 15.917 15.7697 15.917 15.9998Z" stroke="#AD81D2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <div className="">
                    shifts in specific user groups
                  </div>
                </li>
                <li className="flex gap-3 items-center leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                  <svg className="h-[36px] min-w-[36px]" width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="0.5" width="36" height="36" rx="18" fill="#FBF2FF" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3333 10.1665C11.7936 10.1665 12.1667 10.5396 12.1667 10.9998V16.8332C12.1667 17.2934 11.7936 17.6665 11.3333 17.6665C10.8731 17.6665 10.5 17.2934 10.5 16.8332V10.9998C10.5 10.5396 10.8731 10.1665 11.3333 10.1665ZM18 10.1665C18.4602 10.1665 18.8333 10.5396 18.8333 10.9998V14.3332H20.5C20.9602 14.3332 21.3333 14.7063 21.3333 15.1665C21.3333 15.6267 20.9602 15.9998 20.5 15.9998H15.5C15.0398 15.9998 14.6667 15.6267 14.6667 15.1665C14.6667 14.7063 15.0398 14.3332 15.5 14.3332H17.1667V10.9998C17.1667 10.5396 17.5398 10.1665 18 10.1665ZM24.6667 10.1665C25.1269 10.1665 25.5 10.5396 25.5 10.9998V18.4998C25.5 18.9601 25.1269 19.3332 24.6667 19.3332C24.2064 19.3332 23.8333 18.9601 23.8333 18.4998V10.9998C23.8333 10.5396 24.2064 10.1665 24.6667 10.1665ZM18 17.6665C18.4602 17.6665 18.8333 18.0396 18.8333 18.4998V25.9998C18.8333 26.4601 18.4602 26.8332 18 26.8332C17.5398 26.8332 17.1667 26.4601 17.1667 25.9998V18.4998C17.1667 18.0396 17.5398 17.6665 18 17.6665ZM8 20.1665C8 19.7063 8.3731 19.3332 8.83333 19.3332H13.8333C14.2936 19.3332 14.6667 19.7063 14.6667 20.1665C14.6667 20.6267 14.2936 20.9998 13.8333 20.9998H12.1667V25.9998C12.1667 26.4601 11.7936 26.8332 11.3333 26.8332C10.8731 26.8332 10.5 26.4601 10.5 25.9998V20.9998H8.83333C8.3731 20.9998 8 20.6267 8 20.1665ZM21.3333 21.8332C21.3333 21.3729 21.7064 20.9998 22.1667 20.9998H27.1667C27.6269 20.9998 28 21.3729 28 21.8332C28 22.2934 27.6269 22.6665 27.1667 22.6665H25.5V25.9998C25.5 26.4601 25.1269 26.8332 24.6667 26.8332C24.2064 26.8332 23.8333 26.4601 23.8333 25.9998V22.6665H22.1667C21.7064 22.6665 21.3333 22.2934 21.3333 21.8332Z" fill="#AD81D2" />
                  </svg>
                  <div className="">
                    external market events
                  </div>
                </li>
              </ul>
            </div>
            <img className="w-[100%] max-w-[715px]" src={KPI} alt="Mockup." />
          </div> */}
  
        </div>
      </section>
    )
  }